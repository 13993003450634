* {
  box-sizing: border-box;
  font-family: rebound;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: satoshi;
  src: url("./assets/fonts/Satoshi/Satoshi-Regular.woff");
}
@font-face {
  font-family: rebound;
  src: url("./assets/fonts/Rebond_Grotesque/Rebond\ Grotesque.otf")
    format("woff2");
}

@font-face {
  font-family: reboundBold;
  src: url("./assets/fonts/Rebond_Grotesque/Rebond\ Grotesque\ Bold.otf")
    format("woff2");
}

@font-face {
  font-family: reboundLight;
  src: url("./assets/fonts/Rebond_Grotesque/Rebond\ Grotesque\ Light.otf")
    format("woff2");
}

body,
button,
h3 {
  /* background-color: #ebebeb; */
}

.skeleton {
  background: linear-gradient(to right, #f0f0f0 0%, #e0e0e0 50%, #f0f0f0 100%);
  background-size: 200% 100%;
  border-radius: 4px;
  animation: shimmer 1s infinite;
}

.skeleton-loader {
  width: 100%;
  height: 200px;
}

.pagination {
  display: flex !important;
  justify-content: center !important;
  gap: 10px !important;
  align-items: center !important;
  margin-right: 16px !important;
  span {
    color: #858585;
    font-size: 12px !important;
  }
  .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
    padding: 0 !important;
  }
  .css-16c50h-MuiInputBase-root-MuiTablePagination-select {
    cursor: pointer !important;
    border: 1px solid rgba(230, 232, 234, 0.62) !important;
    margin: 0 !important;
    padding: 0 !important;
    background: #fff !important;
    color: #858585 !important;
    svg {
      margin-right: 5px !important;
      font-size: 13px !important;
    }
    .css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input {
      padding: 8.5px 12px !important;
      background: #fff !important;
      margin-right: 14px !important;
    }
  }
}

.footer {
  width: 100% !important;
}
.initialSort .css-levciy-MuiTablePagination-displayedRows {
  border: 3px solid red !important;
  display: none !important;
}

.initialSort .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions {
  display: none !important;
}

.footer .css-pdct74-MuiTablePagination-selectLabel {
  display: none !important;
}

.footer .css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  display: none !important;
}

.footer .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions {
  background: black !important;
  display: flex !important;
  width: 15% !important;
  height: 60% !important;
justify-content: flex-start !important;
  color: white !important;
}

.css-zylse7-MuiButtonBase-root-MuiIconButton-root {
  color: white !important;
  background: black !important;
  display: flex !important;
align-items: center !important;
justify-content: flex-end !important;
position: none !important; 
box-sizing: border-box !important;
outline: 0 !important;
border: 0 !important;
margin: 0 !important;
border-radius: 0 !important;
padding: 0 !important;
cursor: pointer !important;
user-select: none !important;
vertical-align: middle !important;
text-decoration: none !important;
color: inherit !important;
text-align: center !important;
flex: none !important;
font-size: 1.5rem !important;
padding: 8px !important;
border-radius: 0% !important;
overflow: hidden !important;
}

.footer .css-jtlhu6-MuiTablePagination-root:last-child {
  display: flex !important;
  position: absolute !important;
  right: 0 !important;
  width: 400px !important;
  justify-content: center !important;
}
.footer .css-jtlhu6-MuiTablePagination-root {
  display: flex !important;
  width: 100%  !important;
  overflow: hidden !important;
}
.footer .css-1psng7p-MuiTablePagination-spacer {
  display: flex !important;
  /* padding-left: -230px !important; */
}
.footer .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar{
  display: flex !important;
  margin: 36px 0 24px !important;

}
.category-skeleton {
  width: 100%;
  height: 600px;
}

.table-header-skeleton {
  height: 10px;
  margin-bottom: 8px;
}

.pagination-skeleton {
  height: 50px;
  width: 120px;
  margin-bottom: -60px;
  margin-left: auto;
  margin-right: 20px;
}

@keyframes shimmer {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: -100% 50%;
  }
}
